import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  Dialog,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { generalToastStyle } from "../../utils/toast.styles";
import UpdateCoupons from "./updateCoupons.component";
import "./orders.styles.scss";

function convertDateToDDMMYY(date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});
const CouponsComponent = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [ordersList, setOrdersList] = useState([]);
  const [couponList, setCouponList] = useState([]);

  const [couponCode, setCouponCode] = useState("");
  const [couponText, setCouponText] = useState("");
  const [couponPercentage, setCouponPercentage] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponValidity, setCouponValidity] = useState("");
  const [couponHasEndDate, setCouponHasEndDate] = useState(true);
  const [couponAmountType, setCouponAmountType] = useState(0);

  const [updateCouponModal, setUpdateCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const onCreateCouponHandler = async () => {
    setOpen(false);
  };
  const fetchCoupons = async () => {
    try {
      if (!admin.token) return;
      const { data } = await axios.get(
        `https://api.sadashrijewelkart.com/v1.0.0/user/coupons/all.php?type=all_coupons`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("data?.response", data?.response);

      if (Array.isArray(data?.response)) setCouponList(data?.response);
    } catch (err) {
      console.log("fetching coupons failed ", err);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddCoupon = async () => {
    let formData = new FormData();
    console.log(
      "==========================>",
      convertDateToDDMMYY(new Date(couponValidity))
    );
    formData.append("type", "add_coupon");
    formData.append("code", couponCode);
    formData.append(
      "percentage",
      `${couponAmountType === 0 ? couponAmount : 0}`
    );
    formData.append("amount", `${couponAmountType === 1 ? couponAmount : 0}`);
    formData.append("coupon_text", couponCode.toLowerCase());
    const a = couponValidity.split("-");
    formData.append(
      "coupon_validity",
      convertDateToDDMMYY(new Date(couponValidity))
    );
    formData.append("is_active", "1");

    axios
      .post(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/coupons/all.php",
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((_) => {
        toast("Coupon Added", generalToastStyle);
        navigate(0);
      })
      .catch((err) => {
        toast(err.response.data.message, generalToastStyle);
      });
  };

  const handleToggle = (row) => {
    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/coupons/update.php",
        {
          action: "update-coupon",
          is_active: row.is_active == "1" ? "0" : "1",
          id: row.id,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((_) => {
        toast("Coupon status updated", generalToastStyle);
        fetchCoupons();
      })
      .catch((err) => {
        toast(err.response.data.message, generalToastStyle);
      });
  };

  return (
    <div className="category-component">
      <ToastContainer />

      {/* Swipable Drawer */}
      <Dialog
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
          borderRadius: "20px",
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "20px",
          }}
        >
          <p
            style={{
              fontSize: "1.7rem",
              margin: 0,
              fontWeight: "bold",
              width: "95%",
              margin: "auto",
              marginBottom: "30px",
            }}
          >
            Create New Coupon
          </p>

          <div
            style={{
              width: "95%",

              margin: "auto",
              display: "flex",
              justifyItems: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Enter Coupon Code"
                fullWidth
                onChange={(event) => {
                  setCouponCode(event.target.value);
                }}
              ></TextField>
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControl style={{ width: "45%" }}>
                <InputLabel id="demo-simple-select-label">
                  Discount Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Discount Type"
                  style={{ width: "100%" }}
                  onChange={(event) => {
                    setCouponAmountType(event.target.value);
                  }}
                >
                  <MenuItem value={0}>Percentage</MenuItem>
                  <MenuItem value={1}>Price</MenuItem>
                </Select>
              </FormControl>
              <TextField
                placeholder="Discount Value"
                style={{ width: "45%" }}
                onChange={(event) => {
                  setCouponAmount(event.target.value);
                }}
              ></TextField>
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Coupon Start Date"
                style={{ width: "45%" }}
                type="date"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Start Date</InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setCouponValidity(event.target.value);
                }}
              ></TextField>
              {couponHasEndDate ? (
                <TextField
                  placeholder="Coupon End Date"
                  style={{ width: "45%" }}
                  type="date"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">End Date</InputAdornment>
                    ),
                  }}
                ></TextField>
              ) : (
                <TextField
                  placeholder="Coupon End Date"
                  style={{ width: "45%" }}
                  type="date"
                  disabled
                ></TextField>
              )}
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                color: "gray",
                alignItems: "center",
              }}
            >
              <Checkbox
                style={{ border: "gray" }}
                onChange={(event) => {
                  setCouponHasEndDate(!event.target.checked);
                }}
              ></Checkbox>
              <p>Set No End Date for Coupon</p>
            </div>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{
                marginLeft: "auto",
                width: "150px",
                height: "50px",
                color: "black",
                border: "0px",
                fontWeight: 600,
              }}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                marginLeft: "20px",
                width: "250px",
                height: "50px",
                color: "white",
                backgroundColor: "#A36E29",
                fontWeight: 600,
              }}
              onClick={() => handleAddCoupon()}
            >
              Create Coupon
            </Button>
          </div>
        </div>
      </Dialog>

      {/* Update Coupon Modal */}
      <UpdateCoupons
        updateCouponModal={updateCouponModal}
        setUpdateCouponModal={setUpdateCouponModal}
        selectedCoupon={selectedCoupon}
        fetchCoupons={fetchCoupons}
      />
      {/* Main Content */}
      <div className="head">
        <div className="head-txt">Coupons</div>
        <Button className="button" onClick={() => setOpen(true)}>
          <Add /> Create New Coupon
        </Button>
      </div>

      <Divider />

      <ThemeProvider theme={theme}>
        <Paper className="table-paper" elevation={5}>
          {couponList.length === 0 ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <Fragment>
              <TableContainer
                style={{
                  height: "calc(100% - 50px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Coupon Id</TableCell>
                      <TableCell>Coupon Name</TableCell>
                      <TableCell>Coupon Text</TableCell>
                      <TableCell>Created On</TableCell>
                      <TableCell>Valid Till</TableCell>
                      <TableCell>Times Redeemed</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {couponList?.length > 0 &&
                      couponList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <Fragment key={row.id}>
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.coupon_text}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                <TableCell>{row.coupon_validity}</TableCell>
                                <TableCell>{row.redeemed_count}</TableCell>
                                <TableCell>
                                  {Number(row.percentage) > 0
                                    ? `${Number(row.percentage)}%`
                                    : `Rs. ${Number(row.amount)}`}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 700,
                                    color:
                                      row.is_active === "1"
                                        ? "limegreen"
                                        : "gray",
                                  }}
                                >
                                  ⬤{" "}
                                  {row.is_active === "1" ? "Active" : "Expired"}
                                </TableCell>

                                <TableCell
                                  style={{
                                    fontWeight: 800,
                                    fontSize: "1.3rem",
                                  }}
                                >
                                  <Switch
                                    checked={row.is_active === "1"}
                                    onChange={() => handleToggle(row)}
                                    sx={{
                                      "& .MuiSwitch-switchBase.Mui-checked": {
                                        color: "#A36E29",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(163, 110, 41, 0.08)",
                                        },
                                      },
                                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                      {
                                        backgroundColor: "#A36E29",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TableCell className="actions-content">
                                    <Edit
                                      className="allow"
                                      onClick={() => {
                                        setUpdateCouponModal(true);
                                        setSelectedCoupon(row);
                                      }}
                                    />
                                  </TableCell>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={ordersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Fragment>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default CouponsComponent;
