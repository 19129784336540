import { Dialog, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Button, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { generalToastStyle } from "../../utils/toast.styles";
const UpdateCoupons = ({ updateCouponModal, setUpdateCouponModal, selectedCoupon, fetchCoupons}) => {
  const [couponCode, setCouponCode] = useState(null);
  const [couponAmountType, setCouponAmountType] = useState(null);
  const [couponAmount, setCouponAmount] = useState(null);
  const [couponPercentage, setCouponPercentage] = useState(null);
  const [couponValidity, setCouponValidity] = useState(null);
  const [couponHasEndDate, setCouponHasEndDate] = useState(true);
  const [couponText, setCouponText] = useState(null);

  useEffect(() => {
    if (selectedCoupon) {
      setCouponAmountType(selectedCoupon?.percentage !== "0" ? 0 : 1);
      setCouponAmount(selectedCoupon?.amount);
      setCouponPercentage(selectedCoupon?.percentage);
      setCouponCode(selectedCoupon?.code);
      setCouponText(selectedCoupon?.coupon_text);
      setCouponHasEndDate(selectedCoupon?.coupon_validity !== null);
      setCouponValidity(selectedCoupon?.coupon_validity);
    }
  }, [selectedCoupon]);


  const handleUpdateCoupon = () => {
    console.log("couponValidity", couponValidity);
    if (!selectedCoupon) {
      toast.warn("Please select coupon", generalToastStyle);
      return;
    }

    const admin = JSON.parse(localStorage.getItem("admin"));
    const couponId = selectedCoupon?.id;
    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/coupons/update.php",
        {
          action: "update-coupon",
          id: couponId,
          code: couponCode,
          coupon_text: couponText,
          amount: couponAmount ?? 0,
          percentage: couponPercentage ?? 0,
          coupon_validity: couponValidity,
          coupon_has_end_date: couponHasEndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setUpdateCouponModal(false);
        fetchCoupons();
        toast.success("Coupon updated successfully", generalToastStyle);

      })
      .catch((error) => {
        console.error("Error updating coupon:", error);
        toast.error("Error updating coupon", generalToastStyle);
      });
  };

  return (
    <Dialog
      maxWidth={"lg"}
      style={{
        width: "100%",
        height: "100%",
        margin: "auto",
        borderRadius: "20px",
      }}
      open={updateCouponModal}
      onClose={() => {
        setUpdateCouponModal(false);
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "30px",
          height: "max-content",
          width: "759px",
          borderRadius: "20px",
        }}
      >
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "bold",
            width: "95%",
            margin: "auto",
            marginBottom: "30px",
          }}
        >
          Update Coupon
        </p>

        <div
          style={{
            width: "95%",

            margin: "auto",
            display: "flex",
            justifyItems: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Enter Coupon Code"
              fullWidth
              defaultValue={selectedCoupon?.code}
              onChange={(event) => {
                setCouponCode(event.target.value);
              }}
            ></TextField>
          </div>

          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Enter Coupon Text"
              fullWidth
              defaultValue={selectedCoupon?.coupon_text}
              onChange={(event) => {
                setCouponText(event.target.value);
              }}
            ></TextField>
          </div>
          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl style={{ width: "45%" }}>
              <InputLabel id="demo-simple-select-label">
                Discount Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Discount Type"
                style={{ width: "100%" }}
                defaultValue={couponAmountType}
                onChange={(event) => {
                  setCouponAmountType(event.target.value);
                }}
              >
                <MenuItem value={0}>Percentage</MenuItem>
                <MenuItem value={1}>Price</MenuItem>
              </Select>
            </FormControl>
            <TextField
              placeholder="Discount Value"
              style={{ width: "45%" }}
              defaultValue={couponAmountType === 0 ? couponPercentage : couponAmount}
              onChange={(event) => {
                if (couponAmountType === 0) {
                  setCouponPercentage(event.target.value);
                } else {
                  setCouponAmount(event.target.value);
                }
              }}
            ></TextField>
          </div>
          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Coupon Start Date"
              style={{ width: "45%" }}
              type="date"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Start Date</InputAdornment>
                ),
              }}
              defaultValue={new Date(selectedCoupon?.created_at ?? null).toISOString().split('T')[0]}
              onChange={(event) => {
                setCouponValidity(event.target.value);
              }}
            ></TextField>
            {couponHasEndDate ? (
              <TextField
                placeholder="Coupon End Date"
                style={{ width: "45%" }}
                type="date"
                defaultValue={new Date(selectedCoupon?.coupon_validity ?? null).toISOString().split('T')[0]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">End Date</InputAdornment>
                  ),
                }}
              ></TextField>
            ) : (
              <TextField
                placeholder="Coupon End Date"
                style={{ width: "45%" }}
                type="date"
                disabled
              ></TextField>
            )}
          </div>
          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              color: "gray",
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ border: "gray" }}
              onChange={(event) => {
                setCouponHasEndDate(!event.target.checked);
              }}
            ></Checkbox>
            <p>Set No End Date for Coupon</p>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <Button
            style={{
              marginLeft: "auto",
              width: "150px",
              height: "50px",
              color: "black",
              border: "0px",
              fontWeight: 600,
            }}
            variant="outlined"
            onClick={() => setUpdateCouponModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              marginLeft: "20px",
              width: "250px",
              height: "50px",
              color: "white",
              backgroundColor: "#A36E29",
              fontWeight: 600,
            }}
            onClick={() => handleUpdateCoupon()}
          >
            Update Coupon
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateCoupons;